* {
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: "Roboto", "Helvetica Neue", sans-serif;
    font-size: $fontSize;
    color: $textColor;
    background-color: $bodyBgColor;
    margin: 0;
    padding: 0;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
}

a {
    text-decoration: none;
}

.layout-wrapper {
    padding: 0;
    min-height: 100vh;
}

