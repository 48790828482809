/* Configs default from COAD project */
$mainColor: #602A2A;
$secondColor: #ffffff;
$treeColor:  #f2f2f2;
$activeColor: #ffffff;

/* General */
$fontFamily:"Roboto","Helvetica Neue",sans-serif;
$fontSize:14px;
$bodyBgColor:#edf0f5;
$textColor:#333333;
$textSecondaryColor:#707070;
$borderRadius:3px;
$dividerColor:#e3e3e3;
$transitionDuration:.2s;
$maskBgColor:#602A2A;
$primaryColor: #602A2A;

/* Menu Common */
$menuitemBadgeBgColor:#B40C02;
$menuitemBadgeColor:#ffffff;
$submenuFontSize:13px;
$menuitemActiveRouteColor:#FFFFFF;

/* Menu Light */
$menuBgColorFirst:#f3f4f9;
$menuBgColorLast:#d7dbe8;
$menuitemColor:#232428;
$menuitemHoverColor:#0388e5;
$menuitemActiveColor:#0388e5;
$menuitemActiveBgColor:#ffffff;
$menuitemBorderColor:rgba(207, 211, 224, 0.6);

/* Menu Dark */
$menuDarkBgColorFirst: $mainColor;
$menuDarkBgColorLast:$mainColor;
$menuitemDarkColor:$secondColor;
$menuitemDarkHoverColor:$secondColor;
$menuitemDarkActiveColor:$mainColor;
$menuitemDarkActiveBgColor:$secondColor;
$menuitemDarkBorderColor:rgba(52, 56, 65, 0.6);

/* Topbar */
$topbarLeftBgColor:$secondColor;
$topbarRightBgColor:$secondColor;
$topbarItemBadgeBgColor:#ef6262;
$topbarItemBadgeColor:#ffffff;
$topbarItemColor:$mainColor;
$topbarItemHoverColor:#c3e8fb;
$topbarSearchInputBorderBottomColor:#ffffff;
$topbarSearchInputColor:#ffffff;

/* Footer */
$footerBgColor:#ffffff;