.filtro {
    .p-card-footer{
        padding-top: 0px;
    }
    .card.card-w-title {
        padding-bottom:0px;
    }

    .card {
        border: none;
    }

}

.p-card {
    box-shadow: none !important;
}