 .servico {
  // display: flex !important;
  .card-title {
    // height: 100%;
    // display: flex;
    justify-content: center;
    align-items: center;
  }

  height: 45%;
  // width: 33%;
  margin: 20px 0;
  border: 1px solid #602a2a !important;
  border-bottom: 8px #602a2a solid !important;
  color: #602a2a;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
