$mainColor: #602a2a;
$secondColor: #ffffff;
$treeColor: #f2f2f2;

.p-accordion .p-accordion-tab .p-accordion-header a,
.p-accordion
  .p-accordion-tab
  .p-accordion-header:not(.p-disabled).p-highlight
  a {
  background-color: $secondColor;
  border-radius: 5px;
  border-left-width: 7px;
  border-left-color: $mainColor;
  color: #42474a;
  font-weight: normal;
}
.p-accordion-toggle-icon {
  float: right !important;
}

.coad-card-filtro {
  border: 0px
}

.coad-tab-panel-contratos-continuos .p-reset > li {
  border-bottom: 6px solid $mainColor !important;
  border-top: 0px !important;
  width: 49.8% !important;
  text-align: center;
}
.coad-tab-panel-contratos-continuos ul > li > a {
  width: 100%;
  background-color: #ffffff !important;
  border: 0px !important;
}
.coad-tab-panel-contratos-continuos ul > li > a > span {
  color: $mainColor;
  font-size: 14px;
}
